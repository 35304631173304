.DeviceManagementPage {
  .device-table {
    margin: 0 auto;
    max-width: 100%;
  }
}

.ant-card-body {
  display: block !important;
}

