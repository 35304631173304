.profile-form {
  max-width: 500px;
  margin: 5vh auto;
}

.profile-password-form {
  max-width: 500px;
  margin: 5vh auto;
}

.contact-admin-form {
  width: 80%;
  height: 60vh;
  margin: auto;
}

.notification-admin-form {
  max-width: 500px;
  margin: 5vh auto;
}

.ProfilePage{
  .ant-alert-info .ant-alert-icon {
    color: #1890ff;
  }

  .ant-alert-info {
    background-color: #e6f7ff;
    border: 1px solid #91d5ff;
  }
  
  .ant-legacy-form-item-control-wrapper {
    height: 100%;
  }

  .ant-legacy-form-item-control {
    height: 100%;
  }
}
