.ant-table-row:hover .record-actions > * {
  opacity: 1;
  transform: translateX(0px);
  transition: all 0.2s ease;
}

.action {
  transition: all 0.2s ease;
  background-color: transparent;
  transform: translateX(45px);
  opacity: 0;
  cursor: pointer;
}
