.LoginPage {
  a {
    color: black !important;
  }

  a:hover {
    text-decoration: none !important;
    color: #262729;
  }

  max-width: 500px;
  margin: 0 auto;
  padding: 40px;
}
