.live-panel-info-window{
  position: absolute;
  min-width: 150px;
  min-height: 30px;
  background: white;
  border-radius: 5px;
  bottom: 26px;
  left: -63px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 3px #888888;
}

.custom-marker {
  width: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}