.file-drop {
  /* relatively position the container bc the contents are absolute */
  // position: relative;
  // height: 100px;
  // width: 100%;
}

.file-drop > .file-drop-target {
  padding: 10px 4px;
  // position: absolute;
  // top: 0;
  // left: 0;
  // height: 100%;
  // width: 100%;
  border-radius: 10px;
}

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
  /* overlay a black mask when dragging over the frame */
  border: none;
  background-color: rgba(241, 241, 241, 0.65);
  box-shadow: none;
  z-index: 50;
  opacity: 1;
  /* --------------- */

  /* typography */
  color: black;
  /* --------------- */
}

.file-drop > .file-drop-target.file-drop-dragging-over-target {
  /* turn stuff orange when we are dragging over the target */
  color: #1208a0;
}

.geofence-file-drop {
  cursor: crosshair;
}

.ant-list-items {
  overflow: auto;
  max-height: 70vh;
}

.collapse-geofence .ant-collapse-content-box {
  padding: 0px 16px 16px 6px !important;
}


.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  opacity: 0 !important;
}