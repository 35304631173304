.SOSHistoryPage {
  .sos-table {
    margin: 0 auto;
    max-width: 100%;
  }
  .ant-table-thead {
    font-size: 12px;
  }
  .ant-table-row-cell-break-word{
    padding: 16px 13px !important;
  }
}
