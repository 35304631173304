.company-logo {
  margin: 5px 0 0 10px;
  max-width: 8%;
}

.logo {
  max-width: 10%;
  display: block;
  margin: 0 32%;
  padding: 40px 0;
}

#logo {
  width: 400%;
}

.front-logo {
  max-width: 30%;
  display: block;
  margin: 0 auto;
  padding: 40px 0;
}

// scrolbar
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ant-legacy-form-item {
  margin-bottom: 1em !important;
}

.ant-legacy-form-item:last-of-type {
  margin-bottom: 0em !important;
}

.gm-ui-hover-effect {
  outline: none !important;
}

/*Change antd default form styles*/
.ant-legacy-form-item-label {
  text-align: left !important;
  width: 100%;
  line-height: inherit !important;
}

.ant-legacy-form-item-label > label {
  margin-bottom: 0 !important;
}

.anticon {
  vertical-align: 0.1em !important;
}
