.ProfileCounterPage{
  .federation-section {
    display: flex;
    justify-content: left;
    padding: 20px 15px 0 0;

    h6{
      margin-right: 10px;
    }
  }
}