.logo-skeleton {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.top-navigation {
  padding: 0 10px;
}

.sos-btn {
  z-index: 999 !important;
  right: 0;
  top: 0;
  left:0;
}

.sos-btn-with-header {
  z-index: 999 !important;
}

.sos-button-wrapper{
  padding-right: 15px;
}


.sos-btn-with-header-live {
  position : fixed;
  right: 85px;
  z-index: 999 !important;
  top: 15px;
}

.sos-map-modal {
  width: 90% !important;
  position: fixed;
  left: 0;
  right: 0;
  top: 8%;
}

.sos-count-indicator {
  background-color: black;
  color: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
}

.ant-card-body {
  display: none !important;
}

.ant-notification {
  cursor: pointer !important;
}

li {
  a {
    text-decoration: none !important;
  }
}

body {
  font-size: 14px !important;
  color: rgba(0,0,0,.65);
}

.ant-layout {
  background-color: white;
}

.ant-layout-footer {
  background-color: white;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-submenu-selected {
  background-color: #0066CC;
}
