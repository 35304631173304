/* .gmnoprint{
    left: 50% !important;
}

.gm-style-mtc {
    left: -50% !important;
}

div.gm-bundled-control {
    left: 95.7% !important;
    right: 900px !important;
} */

.ap-infobox > div { font-size: 18px; }
