.gmnoprint{
    left: 50% !important;
}

.gm-style-mtc {
    left: -50% !important;
}

div.gm-bundled-control {
    left: auto !important;
    right: 4% !important;
}

.gm-style-cc {
    left: 0 !important;
}