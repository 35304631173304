.profile-counter-table{

  .updated-time{
    text-align: center;
    font-size: 15px;
  }

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 80%;
  }

  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 12px 8px 12px 30px;
    width: 50%;
  }

  td{
    border: 1px solid #dddddd;
    text-align: left;
    padding: 12px 8px 12px 30px;
    width: 25%;
  }
}

.profile-counter-header{
  margin-bottom: 20px;
}


