.privacy-content-wrapper{
  padding: 20px 60px 60px 60px;
  h5{
    text-align: left;
    padding: 5px 100px;
  }
}

.privacy-wrapper{
  padding-top: 20px;
  h2{
    text-align: center;
  }
}

.privacy-content{
  text-align: justify;
  padding: 5px 100px;
}