.react_time_range__tick_label {
  display: none;
}

.react_time_range__time_range_container {
  padding: 1.8% !important;
  width: 100%;
}

.react_time_range__track {
  background-color: #3878c7 !important;
  height: 35px !important;
}

.react_time_range__rail__inner {
  height: 35px !important;
}

.loader_on_search {
  display: flex;
  justify-content: center;
  margin-top: 20%;
}

/*Change default datepicker styles*/

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before, .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before, .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background: transparent !important;
  color: black !important;
  //border: 1px solid #cbe6ff;
}

.ant-picker-cell-range-hover-end::before {

  background: #0000007d !important;
  color: black !important;
  //border: 1px solid #cbe6ff;
  width: 100%;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: transparent;
  border: 1px solid #0b090973;
}

.ant-picker-cell-range-hover {
  background: transparent !important;
}

.ant-picker-cell-in-range {
  //background: transparent !important;

}

.ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  left: 0 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  right: 0 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: #0b090973;
}
