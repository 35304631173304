.hue-picker  {
  & > div {
    position: relative !important;
  }
}

.hue-horizontal {
  & > div {
    position: relative !important;
  }
}