.stopsPage {
  .gm-style-iw+button {
    display: none;
  }

  .gm-style-iw+div {
    display: none;
  }

  // button.gm-ui-hover-effect {
  //   visibility: hidden;
  // }
}