.ant-drawer-content-wrapper > .ant-drawer-content {
    position: relative;
    z-index: 1;
    overflow: auto;
    background-color: rgba(255, 255, 255, 0.1) !important;
    background-clip: padding-box;
    border: 0;
}

/*.ant-drawer-header {*/
/*    position: relative;*/
/*    padding: 16px 24px;*/
/*    color: rgba(0,0,0,.85);*/
/*    background-color: rgba(255, 255, 255, 0.1) !important;*/
/*    border-bottom: 1px solid #f0f0f0;*/
/*    border-radius: 2px 2px 0 0;*/
/*}*/