
  .newStyle {
    border-radius: 20px;
  }
  
  /* applied style to its root properties, but no change */
  .ant-modal-content {
    border-radius: 5px;
    background-color: whitesmoke;
  }

  .ant-modal-header {
    border-radius: 4px;
    background-color: whitesmoke;
  }

  .ant-modal-footer{
    border-bottom: 20px;
    background-color: #E6E6E6;
}