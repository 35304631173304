.wrapper {
  overflow-x: auto;
  height: 55vh;
}

.inner {
  background-color: #000000;
  color:white;
  display: flex;
  padding: 10px;
  p:last-child{
    font-weight: 800;
  }
}

p {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 10px;
}

