.tripsColapse .ant-collapse-content-box {
  height: 67vh !important;
  overflow: scroll;
}

.carousel__dot {
  margin: 0px 2px;
  padding: 5px;
  border-radius: 10px;
  text-align: center;
  border: none;
  background-color: #c5c5c5;
}

.carousel__dot--selected {
  background-color: #0f0f0f;
}
.chat {
  display: flex;
  flex-direction: column;
  // padding: 1px;
}

.messages {
  display: flex;
  flex-direction: column;
}

.message {
  border-radius: 20px;
  padding: 8px 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
}

.yours {
  align-items: flex-start;
}

.yours .message {
  margin-right: 25%;
  background-color: #eee;
  position: relative;
}

.yours .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: -7px;
  height: 20px;
  width: 20px;
  background: #eee;
  border-bottom-right-radius: 15px;
}
.yours .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-right-radius: 10px;
}

.mine {
  align-items: flex-end;
}

// .mine .message {
//   color: white;
//   margin-left: 25%;
//   background: linear-gradient(to bottom, #00d0ea 0%, #0085d1 100%);
//   background-attachment: fixed;
//   position: relative;
// }

// .mine .message.last:before {
//   content: "";
//   position: absolute;
//   z-index: 0;
//   bottom: 0;
//   right: -8px;
//   height: 20px;
//   width: 20px;
//   background: linear-gradient(to bottom, #00d0ea 0%, #0085d1 100%);
//   background-attachment: fixed;
//   border-bottom-left-radius: 15px;
// }

// .mine .message.last:after {
//   content: "";
//   position: absolute;
//   z-index: 1;
//   bottom: 0;
//   right: -10px;
//   width: 10px;
//   height: 20px;
//   background: white;
//   border-bottom-left-radius: 10px;
// }

.sender {
  font-weight: bolder;
  font-size: smaller;
}

.timestamp {
  padding-top: 5px;
  font-size: smaller;
}

.select-space {
  padding-right: 15px;
}

.carousel{
  width: 100% !important;
}

.dashboard-target-info-window {
  .gm-ui-hover-effect {
    display: none !important;
  }
}

.link-button{
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:focus{
  outline: none !important;
}